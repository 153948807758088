import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Card, CardContent } from '@material-ui/core'
import './App.css';
import InfoBox from './components/InfoBox';
import Map from './components/Map';
import Table from './components/Table';
import { prettifyStats, sortData } from './util';
import LineGraph from './components/LineGraph';
import 'leaflet/dist/leaflet.css';

function App() {

  const [countries, setCountries] = useState([]);
  const [mapCountries, setMapCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("worldwide");
  const [countryInfo, setCountryInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [mapCenter, setMapCenter] = useState([24.80746, 10.4796]);
  const [mapZoom, setMapZoom] = useState(1.75);
  const [casesType, setCasesType] = useState("cases");

  useEffect(() => {
    const getCountries = async () => {
      await fetch('https://disease.sh/v3/covid-19/countries')
        .then(res => res.json())
        .then((data) => {
          const c = data.map((country) => (
            {
              name: country.country,
              value: country.countryInfo.iso2
            }
          ));

          setMapCountries(data);

          const sortedData = sortData(data);
          setCountries(c);
          setTableData(sortedData);
        });
    }

    getCountries();
  }, []);

  useEffect(() => {
    fetch("https://disease.sh/v3/covid-19/all")
      .then(res => res.json())
      .then(data => {
        setCountryInfo(data);
      });
  }, [])

  const onCountryChange = async (e) => {
    const url = e.target.value === "worldwide" ? "all" : `countries/${e.target.value}`;
    fetch(`https://disease.sh/v3/covid-19/${url}`)
      .then(res => res.json())
      .then(data => {
        setSelectedCountry(e.target.value);
        setCountryInfo(data);

        if (e.target.value === "worldwide") {
          setMapCenter([34.80746, -40.4796]);
          setMapZoom(1.75);
          return;
        }

        setMapCenter([data.countryInfo.lat, data.countryInfo.long])
        setMapZoom(4);
      });
  }

  return (
    <div className="app">

      <div className='app__left'>
        <div className='app__header'>
          <h1>Covid-19 Tracker</h1>
          {countries.length > 0 && (
            <FormControl className='app__dropdown'>
              <Select variant='outlined' onChange={(e) => onCountryChange(e)} value={selectedCountry}>
                <MenuItem key={-1} value="worldwide">Worldwide</MenuItem>
                {countries.map((country, i) => (
                  <MenuItem key={i} value={country.value}>{country.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>

        <div className='app__stats'>
          <InfoBox caseType="cases"
          isRed
          active = {casesType === "cases"}
          setCasesType={setCasesType} 
          title="Corovirus Cases"
          total={prettifyStats(countryInfo.cases)} 
          cases={prettifyStats(countryInfo.todayCases)} />

          <InfoBox caseType="recovered" 
          active = {casesType === "recovered"}
          setCasesType={setCasesType} 
          title="Recovered"
          total={prettifyStats(countryInfo.recovered)}
          cases={prettifyStats(countryInfo.todayRecovered)} />

          <InfoBox caseType="deaths" 
          isRed
          active = {casesType === "deaths"}
          setCasesType={setCasesType} 
          title="Deaths" 
          total={prettifyStats(countryInfo.deaths)} 
          cases={prettifyStats(countryInfo.todayDeaths)} />

        </div>

        <div>
          <Map center={mapCenter} zoom={mapZoom} countriesData={mapCountries} casesType={casesType} />
        </div>

      </div>

      <Card className='app__right'>
        <CardContent>
          <h3>Live cases by Country</h3>
          <Table countries={tableData} />

          <h3 className='app__right__cases'>Worldwide new {casesType}</h3>
          <LineGraph casesType={casesType} />

        </CardContent>
      </Card>

    </div>
  );
}

export default App;
