import { useEffect, useState } from 'react'
import numeral from 'numeral'
import './Table.css'

const Table = ({ countries }) => {
    const [filteredData, setFilteredData] = useState([]);

    const onFilterDataChange = (e) => {

        if (e.target.value) {
            setFilteredData(countries.filter(data => data.country.toLowerCase().includes(e.target.value.toLowerCase())));
        }
        else{
            setFilteredData(countries);
        }
    }

    useEffect(() => {
        setFilteredData(countries)
    }, [countries])

    return (
        <div>
            <input onChange={onFilterDataChange} className='searchBox' type="text" placeholder='Search country' />
            {filteredData && (
                <table className='table'>
                    <tbody className='table__body'>
                        {filteredData.map(({ country, active }, i) => (
                            <tr key={i}>
                                <td>{country}</td>
                                <td>{numeral(active).format("0,0")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default Table