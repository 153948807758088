import './Map.css'
import { Circle, LayerGroup, MapContainer, Popup, TileLayer, useMap } from 'react-leaflet'
import { casesTypeProps } from '../util';
import numeral from 'numeral';

const Map = ({ center, zoom, countriesData, casesType }) => {

  function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, zoom);

    return null;
  }

  return (
    <div className='map'>
      <MapContainer scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ChangeMapView coords={center} zoom={zoom} />

        <LayerGroup>
          {
            countriesData.map((country, i) => (

              <Circle key={i}
                pathOptions={{ color: casesTypeProps[casesType].hex, fillColor: casesTypeProps[casesType].hex }}
                center={[country.countryInfo.lat, country.countryInfo.long]}
                fillOpacity={0.4}
                radius={Math.sqrt(country[casesType]) * casesTypeProps[casesType].multiplier}
              >
                <Popup>
                  <div className='circle-info-container'>
                    <div className='circle-info-container__flag' style={{backgroundImage: `url(${country.countryInfo.flag})`}}></div>
                    <div className='circle-info-container__name'>{country.country}</div>
                    <div className='circle-info-container__cases'>Cases: {numeral(country.cases).format("0,0")}</div>
                    <div className='circle-info-container__recovered'>Recovered: {numeral(country.recovered).format("0,0")}</div>
                    <div className='circle-info-container__deaths'>Deaths: {numeral(country.deaths).format("0,0")}</div>
                  </div>
                </Popup>
              </Circle>
            ))
          }
        </LayerGroup>

      </MapContainer>
    </div>
  )
}

export default Map