import numeral from 'numeral';

export const sortData = (data) => {
    const sortedData = [...data];

    sortedData.sort((a, b) => {
        if (a.active > b.active) {
            return -1;
        }
        else {
            return 1;
        }
    })

    return sortedData;
}

export const prettifyStats = (stat) => stat ? `+${numeral(stat).format("0.0a")}` : "+0";

export const buildChartData = (data, casesType = 'cases') => {
    const chartData = [];
    
    let lastDataPoint;
    for (let date in data[casesType]) {
        if (lastDataPoint) {
            const newDataPoint = {
                x: date,
                y: Math.max(0, data[casesType][date] - lastDataPoint)
            }

            chartData.push(newDataPoint)
        }

        lastDataPoint = data[casesType][date];
    }

    return chartData;
}

export const casesTypeProps = {
    cases: {
        hex: "#CC1034",
        multiplier: 80,
    },
    recovered: {
        hex: "#7DD71D",
        multiplier: 120,
    },
    deaths: {
        hex: "#GB4442",
        multiplier: 500,
    }
}
