import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler,
} from 'chart.js'
import { time } from 'chartjs-adapter-date-fns' //Needed for line graph time formatting 

import { buildChartData } from '../util'
import numeral from 'numeral'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler
);

const LineGraph = ({casesType}) => {

    const [data, setData] = useState({})
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            maintainAspectRatio: false,
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        return numeral(tooltipItem.formattedValue).format("+0,0");
                    }
                }
            },
            formatter: function (value, context) {
                return context.chart.data.labels[context.dataIndex] + ':';
            }
        },
        fill: {
            target: 'Shape',           
        },
        scales: {
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    callback: function (value, index, values) {
                        return numeral(value).format("0a");
                    }
                }
            },
            x: {
                grid: {
                    display: false
                },
                type: 'time',
                time: {
                    unit: 'month',
                    parser: 'M/dd/yy'
                }
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetch("https://disease.sh/v3/covid-19/historical/all?lastdays=365")
                .then(res => res.json())
                .then(data => {
                    const chartData = buildChartData(data, casesType)
                    setData(chartData);
                })
        }

        fetchData();
    }, [casesType])

    return (
        <div>
            {data?.length > 0 && (
                <Line data={{
                    datasets: [{
                        backgroundColor: "rgba(204, 16, 52, 0.5)",
                        borderColor: "#CC1034",
                        data: data,
                        pointRadius: 0,
                        tension: 0.2,
                        borderWidth: 2,
                        fill: {
                            target: 'origin',
                            below: "rgba(204, 16, 52, 0.3)"    // And blue below the origin
                          }
                    }]
                }} options={options} />
            )}
        </div>
    )
}

export default LineGraph