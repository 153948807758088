import { Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'

const InfoBox = ({ caseType, setCasesType, title, cases, total, active, isRed }) => {
    return (
        <Card className={`infoBox ${active && 'infoBox--selected'} ${isRed && 'infoBox--red'}`} onClick={() => setCasesType(caseType)}>
            <CardContent>
                <Typography className='infoBox__title' color='textSecondary'>{title}</Typography>
                <h2 className={`infoBox__cases ${!isRed && 'infoBox--green'}`}>{cases}</h2>
                <Typography className='infoBox__total' color='textSecondary'>{total} Total</Typography>
            </CardContent>
        </Card>
    )
}

export default InfoBox